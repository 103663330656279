import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')


{
  const elem = document.getElementById('rec347621271');
  elem.style.display = 'none';
}

<template>
  <div id="tab-stand">
    <div id="tab-stand-select">
      <div
          v-for="e in stands"
          :key="e.id"
          @click="changeStand(e)"
          class="tab-stand-select-item"
          :class="{
            'tab-stand-select-item__active': e.id === stand.id,
          }"
      >
        {{e.name}}
      </div>
    </div>
    <div id="tab-stand-image">
      <img :src="stand.img" alt="">
    </div>
    <TabFooterDescription/>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {mapActions, mapGetters} from "vuex";
import TabDescription from "@/components/TabDescription";
import TabFooterDescription from "@/components/TabFooterDescription";

export default {
  name: "TabStand",
  components: {
    VueSlickCarousel,
    TabDescription,
    TabFooterDescription
  },
  computed: {
    ...mapGetters([
      'stand',
    ]),
  },
  methods: {
    ...mapActions([
      'changeStand'
    ]),
  },
  data: () => ({
    stands: [
      {
        img: 'https://i.ibb.co/ZTKdyWq/cut-around.png',
        name: 'По контуру',
        id: 1,
      },
      {
        img: 'https://i.ibb.co/1nCH3MS/rectangle.png',
        name: 'Прямоугольник',
        id: 2,
      },
    ],
  }),

}
</script>

<style scoped>
#tab-stand {
  display: flex;
  align-items: stretch;
}
#tab-stand-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
#tab-stand-image img{
  width: 100%;
}
@media (max-width: 960px) {
  #tab-stand-image img {
    max-height: 85px;
    width: auto;
  }
}
#tab-stand-select {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.tab-stand-select-item {
  width: calc(100% / 2);
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  box-sizing: border-box;
  cursor: pointer;
  transition: .3s;
  border: 1px solid #8b00ff;
}
.tab-stand-select-item__active {
  background-color: #8b00ff;
  color: white;
}
</style>
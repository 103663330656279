<template>
  <div class="tab-description">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TabDescription"
}
</script>

<style>
.tab-description {
  padding: 15px;
  background-color: rgba(255,255,255,0.05);
  margin-top: 10px;
  font-size: 12px;
  opacity: .4;
}
</style>
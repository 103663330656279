<template>
<div id="tab-color">
  <div id="tab-color-carousel">
    <VueSlickCarousel v-bind="settings">
      <div v-for="e in colors" :key="e.id">
        <div class="tab-color-item" :class="e">
          <div
              class="tab-color-item-inner"
              @click="changeColor(e)"
              :style="{
                backgroundColor: e.previewColor || e.glowColor,
              }"
              :class="{'tab-color-item-inner__active': e.id === color.id,}"
          >
            <div class="tab-color-item-inner-icon">
              <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px"><path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M21.707,12.707l-7.56,7.56 c-0.188,0.188-0.442,0.293-0.707,0.293s-0.52-0.105-0.707-0.293l-3.453-3.453c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0 l2.746,2.746l6.853-6.853c0.391-0.391,1.023-0.391,1.414,0S22.098,12.316,21.707,12.707z"/></svg>
            </div>
          </div>
        </div>
      </div>
      <template #prevArrow="arrowOption">
        <div class="custom-arrow custom-arrow-left">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#ffffff" class="bi bi-chevron-left" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/></svg>
        </div>
      </template>
      <template #nextArrow="arrowOption">
        <div class="custom-arrow custom-arrow-right">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>
        </div>
      </template>
    </VueSlickCarousel>
  </div>
  <TabDescription>
    Цвета могут выглядить иначе в реальной жизни
  </TabDescription>
  <TabFooterDescription/>
</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import TabDescription from "@/components/TabDescription"
import TabFooterDescription from "@/components/TabFooterDescription"
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {mapActions, mapGetters} from "vuex";

export default {
  name: "TabColor",
  components: {
    VueSlickCarousel,
    TabDescription,
    TabFooterDescription
  },
  computed: {
    ...mapGetters([
      'color',
    ]),
  },
  methods: {
    ...mapActions([
      'changeColor'
    ]),
  },
  data: () => ({
    settings: {
      arrows: true,
      infinite: false,
      rows: 2,
      dots: false,
      initialSlide: 0,
      draggable: false,
      slidesToShow: 5,
      swipeToSlide: false,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            draggable: true,
            infinite: true,
            swipeToSlide: true,
            rows: 1,
            slidesToShow: 8,
          },
        },
        {
          breakpoint: 600,
          settings: {
            draggable: true,
            infinite: true,
            swipeToSlide: true,
            rows: 1,
            slidesToShow: 4,
          },
        },
      ],
    },
    colors: [
      {lineColor: '#fffac5', glowColor: '#ffb700', name: 'Оранжевый', id: 0,},
      {lineColor: '#f0ffff', glowColor: '#fffcf485', previewColor: '#f7fbff', name: 'Холодный белый', id: 1,},
      {lineColor: '#f0ffff', glowColor: 'rgba(255,242,178,0.5)', previewColor: '#fffbf0', name: 'Теплый белый', id: 2,},
      {lineColor: '#c6fcff', glowColor: '#00ffff85', previewColor: '#00ffff', name: 'Голубой', id: 3,},
      {lineColor: '#ffd0ff', glowColor: '#6300cc', name: 'Фиолетовый', id: 4,},
      {lineColor: '#ffffd5', glowColor: '#ffff0085', previewColor: '#ffff00', name: 'Жёлтый', id: 5,},
      {lineColor: '#FFD4D2', glowColor: '#ff0a00', name: 'Красный', id: 6,},
      {lineColor: '#c3ffc5', glowColor: '#00ff0a95', previewColor: '#00ff0a', name: 'Зелёный', id: 7,},
      {lineColor: '#ffcbff', glowColor: '#ff2dff', name: 'Розовый', id: 8,},
      {lineColor: '#CBCFFF', glowColor: '#0017ff', name: 'Синий', id: 9,},
    ],
  }),

}
</script>

<style scoped>
#tab-color {
  display: flex;
  align-items: stretch;
}
#tab-color-carousel {
  box-sizing: border-box;
  width: 100%;
}
.tab-color-item {
  display: flex;
  padding: 5px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.tab-color-item-inner {
  background-color: rgba(255,255,255,0.11);
  width: 40px;
  height: 40px;
  display: flex;
  transition: .3s;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.tab-color-item-inner__active {
  transform: scale(0.85, 0.85);
  background-color: white;
  color: black;
}
.tab-color-item .tab-color-item-inner-icon {
  display: none;
  width: 25px;
  height: 25px;
}
.tab-color-item .tab-color-item-inner-icon svg {
  width: 100%;
  height: 100%;
}
.tab-color-item-inner__active .tab-color-item-inner-icon {
  display: block;
}
.custom-arrow {
  width: 25px;
  height: 25px;
}
.custom-arrow-left {
  left: -30px;
}
.custom-arrow-right {
  right: -30px;
}
.custom-arrow svg {
  width: 100%;
  height: 100%;
}
.custom-arrow::before {
  display: none;
}
@media (max-width: 960px) {
  .custom-arrow {
    width: 15px;
    height: 15px;
  }
  .custom-arrow-left {
    left: -20px;
  }
  .custom-arrow-right {
    right: -20px;
  }
  #tab-color-carousel {
    width: 100%;
    padding: 0 20px;
    align-self: center;
  }
}

</style>
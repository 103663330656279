import Vue from 'vue'
import Vuex from 'vuex'
import {backgrounds, formId} from "@/ext";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        sign: {
            text: '',
            font: 'fontNickainleyNormal',
            size: 15,
            isWireWhite: true,
            color: {lineColor: '#fffac5', glowColor: '#ffb700', name: 'Оранжевый', id: 0,},
            stand: {
                img: 'https://i.ibb.co/ZTKdyWq/cut-around.png',
                name: 'По контуру',
                id: 1,
            },
        },
        background: 1,
        backgrounds: backgrounds,
    },
    mutations: {
        CHANGE_SIGN_PARAM(state, { param, val }) {
            Vue.set(state.sign, param, val)
        },
        CHANGE_ACTIVE_BACKGROUND(state, val) {
            console.log(val)
            state.background = val
        },
        pushBackground(state, val) {
            if (backgrounds.filter(e => e.id === val.id).length === 0) {
                state.backgrounds.push(val);
            }
        },
    },
    actions: {
        changeText({ commit }, val) {
            commit('CHANGE_SIGN_PARAM', {
                param: 'text',
                val: val
            })
        },
        changeFont({ commit }, val) {
            commit('CHANGE_SIGN_PARAM', {
                param: 'font',
                val: val
            })
        },
        changeWire({ commit }, val) {
            commit('CHANGE_SIGN_PARAM', {
                param: 'isWireWhite',
                val: val
            })
        },
        changeColor({ commit }, val) {
            commit('CHANGE_SIGN_PARAM', {
                param: 'color',
                val: val
            })
        },
        changeStand({ commit }, val) {
            commit('CHANGE_SIGN_PARAM', {
                param: 'stand',
                val: val
            })
        },
        changeBackground({ commit }, val) {
            commit('CHANGE_ACTIVE_BACKGROUND', val)
        },
        pushBackground({ commit }, val) {
            commit('pushBackground', val);
        },
    },
    getters: {
        text: state => state.sign.text,
        font: state => state.sign.font,
        size: state => state.sign.size,
        background: state => state.background,
        backgrounds: state => state.backgrounds,
        color: state => state.sign.color,
        isWireWhite: state => state.sign.isWireWhite,
        stand: state => state.sign.stand,
        sign: state => state.sign,
        standFormat: ({ sign }) => ({
            text: `Текст вывески: ${sign.text}`,
            font: `Шрифт: ${sign.font.substr(4)}`,
            color: `Цвет неона: ${sign.color.name}`,
            stand: `Подложка: ${sign.stand.name}`,
            isWireWhite: `Кабель питания: ${sign.isWireWhite ? 'Белый' : 'Чёрный'}`,
        }),
        shadow: state => {
            const autoBlur = blur => `${blur}px`;
            const glowColor = state.sign.color.glowColor;

            return `
              2px 2px ${autoBlur(1)} rgba(0, 0, 0, 0.1),
              0 0 ${autoBlur(1)} ${glowColor},
              0 0 ${autoBlur(8)} ${glowColor},
              0 0 ${autoBlur(8)} ${glowColor},
              0 0 ${autoBlur(8)} ${glowColor},
              0 0 ${autoBlur(10)} ${glowColor},
              0 0 ${autoBlur(15)} ${glowColor},
              0 0 ${autoBlur(20)} ${glowColor},
              0 0 ${autoBlur(20)} ${glowColor},
              0 0 ${autoBlur(20)} ${glowColor},
              0 0 ${autoBlur(40)} ${glowColor},
              0 0 ${autoBlur(40)} ${glowColor},
              0 0 ${autoBlur(40)} ${glowColor}`;
        },
    },
})
<template>
<div id="tab-text">
  <textarea
      rows="2"
      id="tab-text-textarea"
      :value="text"
      @input="updateMessage"
      placeholder="Ваш текст"
  ></textarea>
  <TabDescription>
    Введите желаемый текст в поле выше
  </TabDescription>
  <TabFooterDescription/>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TabDescription from "@/components/TabDescription";
import TabFooterDescription from "@/components/TabFooterDescription";

export default {
  name: "TabText",
  components: {
    TabDescription,
    TabFooterDescription
  },
  computed: {
    ...mapGetters([
        'text',
        'sign'
    ]),
    showSign() {
      return this.sign
    },
  },
  methods: {
    ...mapActions([
        'changeText'
    ]),
    updateMessage(e) {
      this.changeText(e.target.value);
    }
  },
}
</script>

<style scoped>
#tab-text-textarea {
  padding: 20px;
  border: 1px solid white;
  border-radius: 0;
  font-size: 16px;
  color: white;
  box-sizing: border-box;
  background-color: transparent;
  resize: none;
  outline: none;
  font-family: 'Geometria', sans-serif;
}
@media (max-width: 960px) {
  #tab-text-textarea {
    padding: 10px;
  }
}
</style>
<template>
<div id="tab-wire">
  <div id="tab-wire-select">
    <div class="tab-wire-select-item" :class="{'tab-wire-select-item__active': isWireWhite,}" @click="changeWire(true)">Белый</div>
    <div class="tab-wire-select-item" :class="{'tab-wire-select-item__active': !isWireWhite,}" @click="changeWire(false)">Чёрный</div>
  </div>
  <div class="tab-description">
    На данной вкладке можно выбрать цвет провода питания
  </div>
  <div class="tab-description">
    Также вы сможете добавить любой эскиз или символы к вашему тексту после заполнения формы обратной связи.
  </div>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "TabWire",
  computed: {
    ...mapGetters([
      'isWireWhite',
    ]),
  },
  methods: {
    ...mapActions([
      'changeWire'
    ]),
  },
}
</script>

<style scoped>
#tab-wire-select {
  width: 100%;
  display: flex;
  align-items: center;
}
.tab-wire-select-item {
  width: 50%;
  text-align: center;
  padding: 10px 0;
  box-sizing: border-box;
  cursor: pointer;
  transition: .3s;
  border: 1px solid #8b00ff;
}
.tab-wire-select-item__active {
  background-color: #8b00ff;
  color: white;
}
</style>
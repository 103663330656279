<template>
  <TabDescription>
    Также вы сможете добавить любой эскиз или символы к вашему тексту после заполнения формы обратной связи.
  </TabDescription>
</template>

<script>
import TabDescription from "@/components/TabDescription"

export default {
  name: "TabFooterDescription",
  components: {
    TabDescription
  },
}
</script>
<template>
<div id="tab-font">
  <div id="tab-font-carousel">
    <VueSlickCarousel v-bind="settings">
      <div v-for="e in fonts">
        <div class="tab-font-item" :class="e">
          <div class="tab-font-item-inner" @click="changeFont(e)" :class="{'tab-font-item-inner__active': e === font,}">
            Aa
          </div>
        </div>
      </div>
      <template #prevArrow="arrowOption">
        <div class="custom-arrow custom-arrow-left">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#ffffff" class="bi bi-chevron-left" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/></svg>
        </div>
      </template>
      <template #nextArrow="arrowOption">
        <div class="custom-arrow custom-arrow-right">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>
        </div>
      </template>
    </VueSlickCarousel>
  </div>
  <TabDescription>
    Расстояние между буквами может отличаться от действительного
  </TabDescription>
  <TabFooterDescription/>
</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {mapActions, mapGetters} from "vuex";
import TabDescription from "@/components/TabDescription";
import TabFooterDescription from "@/components/TabFooterDescription";

export default {
  name: "TabFont",
  components: {
    VueSlickCarousel,
    TabDescription,
    TabFooterDescription
  },
  computed: {
    ...mapGetters([
      'font',
    ]),
  },
  methods: {
    ...mapActions([
      'changeFont'
    ]),
  },
  data: () => ({
    settings: {
      arrows: true,
      rows: 2,
      infinite: false,
      dots: false,
      initialSlide: 0,
      slidesToShow: 5,
      draggable: false,
      slidesToScroll: 2,
      swipeToSlide: false,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            rows: 1,
            draggable: true,
            infinite: true,
            slidesToScroll: 1,
            swipeToSlide: true,
            slidesToShow: 8,
          },
        },
        {
          breakpoint: 600,
          settings: {
            draggable: true,
            rows: 1,
            infinite: true,
            slidesToScroll: 1,
            swipeToSlide: true,
            slidesToShow: 4,
          },
        },
      ],
    },
    fonts: [
      'fontNickainleyNormal',
      'fontBankGothicRUSSMedium',
      'fontBoleroscript',
      'fontGalaktika',
      'fontNeuropolMedium',
      'fontCyrillicRibbon',
      'fontSegoePrintRegular',
      'fontssvivas',
      'fontForestSmooth',
      'fontArial',
    ],
  }),

}
</script>

<style scoped>
#tab-font {
  display: flex;
  align-items: stretch;
}
#tab-font-carousel {
  box-sizing: border-box;
  width: 100%;
}
.tab-font-item {
  display: flex;
  padding: 5px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.tab-font-item-inner {
  background-color: rgba(255,255,255,0.11);
  width: 40px;
  height: 40px;
  transition: .3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-font-item-inner__active {
  background-color: white;
  transform: scale(0.85, 0.85);
  color: black;
}
.custom-arrow {
  width: 25px;
  height: 25px;
}
.custom-arrow-left {
  left: -30px;
}
.custom-arrow-right {
  right: -30px;
}
.custom-arrow svg {
  width: 100%;
  height: 100%;
}
.custom-arrow::before {
  display: none;
}
@media (max-width: 960px) {
  .custom-arrow {
    width: 15px;
    height: 15px;
  }
  .custom-arrow-left {
    left: -20px;
  }
  .custom-arrow-right {
    right: -20px;
  }
  #tab-font-carousel {
    width: 100%;
    padding: 0 20px;
    align-self: center;
  }
}
</style>